import style from './HomeGuid.module.css';

const categoryData = [
  {
    name: "Instagram",
    actions: ["1.Save", "2.Comment", "3.Share", "4.Like"],
  },
  {
    name: "WhatsApp",
    actions: ["1.Share", "2.Forward to minimum 2 groups"],
  },
  {
    name: "Facebook",
    actions: ["1.Like", "2.Share"],
  },
  {
    name: "LinkedIn",
    actions: ["1.Like", "2.Share", "3.Comment"],
  },
  {
    name: "Twitter",
    actions: ["1.Retweet", "2.Like", "3.Comment"],
  },
];

const eventCounts = [15, 10, 10, 15, 10];

const TableTwo = () => {
  return (
    <div className="mb-10 md:mb-28">
      <h1 className={`w-full h-[155px] py-8 text-white text-[2rem] md:text-[5rem] font-nexabold font-semibold text-center tracking-normal leading-normal ${style.textShadow}`}>
        Table 1.2
      </h1>
      <div className="flex justify-center items-center">
        <div className={`mx-[4%] flex flex-col md:flex-row justify-around items-start bg-gradient-to-br from-[#291263] to-[#21232F] to-96% ${style.gradientBorder} p-10 h-auto md:h-[700px] w-full lg:w-[86rem]`}>
          <div className="flex flex-col justify-between items-center mb-10 md:mb-0">
            <h2 className={`text-2xl md:text-4xl mb-4 md:mb-6 font-nexabold ${style.textShadow}`}>
              CATEGORY
            </h2>
            <div className="flex flex-col gap-4 text-lg font-bold">
              {categoryData.map((category, index) => (
                <div key={index} className="flex flex-col justify-center items-center rounded-xl border-2 border-[#09fbd3] h-auto md:h-[100px] bg-gradient-to-br from-[#291263] to-[#21232F] to-96% p-4 text-xs md:text-lg">
                  <p className="mb-2">{category.name}</p>
                  <p className="font-normal flex flex-wrap justify-center">
                    {category.actions.map((action, idx) => (
                      <span key={idx} className="mr-3">{action}</span>
                    ))}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {["FLAGSHIP EVENTS", "WORKSHOPS", "CHAMPIONSHIPS", "POINTS"].map((title, index) => (
            <div key={index} className="flex flex-col justify-between items-center mb-10 md:mb-0 md:mx-0">
              <h2 className={`text-xl md:text-2xl mb-4 md:mb-6  font-nexabold ${style.textShadow}`}>
                {title}
              </h2>
              <div className="flex flex-row md:flex-col gap-4 md:gap-9 mt-0 md:mt-5 text-xs md:text-lg font-bold">
                {eventCounts.map((count, idx) => (
                  <div key={idx} className="flex justify-center items-center">
                    <div className="flex justify-center items-center rounded-full border-2 border-[#09fbd3] bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-[40px] w-[40px] md:h-[80px] md:w-[80px] p-2">
                      <p>{count}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TableTwo;
