import style from "./HomeGuid.module.css";

const registrationData = [
  { registration: 25, points: 50 },
  { registration: 50, points: 100 },
  { registration: 100, points: 150 },
  { registration: 150, points: 200 },
  { registration: 200, points: 25 },
];

const TableThree = () => {
  return (
    <div className="pb-10">
      <h1
        className={`w-full h-[155px] py-8 text-white text-[2rem] sm:text-sm md:text-[5rem] font-nexabold font-semibold text-center tracking-normal leading-normal ${style.textShadow}`}
      >
        Table 1.3
      </h1>
      <div className="flex justify-center items-center">
        <div
          className={`flex flex-col mx-4 bg-gradient-to-br from-[#291263] to-[#21232F] to-96% ${style.gradientBorder} p-4 md:pl-10 h-[450 px] md:h-[700px] md:w-[86rem]`}
        >
          <div className="hidden md:flex justify-around items-center text-2xl md:text-4xl font-bold my-8">
            <h1 className={`font-nexabold tracking-normal ${style.textShadow}`}>
              TOTAL REGISTRATIONS
            </h1>
            <h1
              className={`font-nexabold tracking-normal mr-20 ${style.textShadow}`}
            >
              POINTS
            </h1>
          </div>
          <div className="flex flex-col justify-center font-bold md:mt-10 mb-4 gap-4 md:ml-10 pt-4 md:pt-0">
            <div className="md:hidden flex justify-around items-center text-2xl font-bold ml-8">
              <h1 className="flex justify-center items-center rounded-3xl h-10 w-32">
                Registration
              </h1>
              <h1 className="flex justify-center items-center rounded-3xl h-10 w-32">
                Points
              </h1>
            </div>
            <div className="table w-full md:w-auto">
              {registrationData.map((item, index) => (
                <div key={index} className="table-row">
                  <div className="table-cell w-full md:w-1/2 p-2">
                    <div className="flex justify-center items-center">
                      <h1 className="flex justify-center items-center rounded-2xl border-2 border-[#09fbd3] bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-10 w-56 md:h-20 md:w-[500px] md:px-8 px-2">
                        {item.registration}
                      </h1>
                    </div>
                  </div>
                  <div className="table-cell w-full md:w-1/2 p-3">
                    <div className="flex justify-center items-center">
                      <h1 className="flex justify-center items-center rounded-3xl border-2 border-[#09fbd3] bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-10 w-10 md:h-20 md:w-20">
                        {item.points}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableThree;
