import style from "./HomeGuid.module.css"

const data = [
  {
    task: "Update your social media profiles: Instagram, Twitter, Facebook, LinkedIn",
    points: 50,
  },
  {
    task: "Mention on LinkedIn profile: ‘Campus Ambassador at Aaruush'24'",
    points: 20,
  },
  {
    task: "Follow AARUUSH social media handles",
    points: 40,
  },
  {
    task: "(CA)Registrations using Reference from the same college",
    points: 25,
  },
  {
    task: "(CA)Registrations using Reference from another college",
    points: 50,
  },
  {
    task: "Previous Year participants coming back (Loyalty)",
    points: 60,
  },
  {
    task: "Registrations for unpaid Events/Workshops",
    points: "40/reg.",
  },
  {
    task: "Registrations for paid Events/Workshops",
    points: "70/reg.",
  },
  {
    task: "Registrations for Common Registration",
    points: 75,
  },
  {
    task: "Social Media Drills",
    points: "T 1.2",
  },
  {
    task: "Milestone Bonus Points",
    points: "T 1.3",
  },
  {
    task: "Testimonial section",
    points: 50,
  },
  {
    task: "Weekly Questions",
    points: 20,
  },
  {
    task: "Rank Upgrade",
    points: 50,
  },
  {
    task: "Competitions",
    points: [
      "1st(100)",
      "2nd(75)",
      "3rd(50)"
    ],
  },
  {
    task: "Top the Clan Leader board",
    points: [
      "1st(100)",
      "2nd(75)",
      "3rd(50)"
    ],
  },
];

const PointSystem = () => {
  return (
    <div className="mb-10 md:mb-28 ml-3 md:ml-0">
      <h1 className={`w-full h-[155px] py-4 md:py-8 text-white sm:text-sm text-[2rem] md:text-[5rem] font-nexabold font-semibold text-center tracking-normal leading-normal ${style.textShadow}`}>
        POINT SYSTEM
      </h1>
      <div className="flex flex-col justify-center">
        <div className="mb-4">
          <p className={`font-nexa rounded-lg p-5 mx-auto text-justify text-lg flex flex-col gap-y-5 font-normal mb-5 bg-gradient-to-br from-[#291263] to-[#21232F] to-96% ${style.gradientBorder} xl:right-0 lg:right-64 lg:w-[84rem]`}>
            The Campus Ambassadors will gain points basis of the following criteria:
          </p>
        </div>
        <div className="flex justify-around items-center text-xl md:text-6xl font-bold max-md:hidden md:ml-12">
          <h1 className={` font-nexabold tracking-normal ${style.textShadow}`}>TASKS</h1>
          <h1 className={` font-nexabold tracking- mr-10 ${style.textShadow}`}>POINTS</h1>
        </div>
        <div className="flex flex-wrap flex-col justify-center font-bold md:mt-10 mb-4 gap-4 sm:mx-6">
          <div className="flex justify-around items-center text-4xl font-bold md:hidden">
            <h1 className={`flex text-2xl font-nexabold tracking-normal md:hidden ${style.textShadow}`}>
              Task
            </h1>
            <h1 className={`flex text-2xl font-nexabold tracking-normal md:hidden pl-28 md:pl-0 ${style.textShadow}`}>
              Points
            </h1>
          </div>
          {data.map((item, index) => (
            <div key={index} className="flex items-center text-xs md:text-lg font-bold px-2">
              <div className="md:w-1/2">
                <div className="flex w-full justify-center items-center ">
                  <h1 className="flex justify-start items-center rounded-xl bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-14 w-60  md:h-20 md:w-[500px] md:px-8 px-2 ">
                    {item.task}
                  </h1>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex flex-col md:flex-row gap-3 w-full justify-center items-center gap">
                  {Array.isArray(item.points) ? (
                    item.points.map((point, i) => (
                      <h1 key={i} className="flex justify-center items-center bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-14 w-14 md:h-20 md:w-20 text-[0.7rem] md:text-[0.9rem] rounded-full p-6">
                        {point}
                      </h1>
                    ))
                  ) : (
                    <h1 className="flex justify-center items-center bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-14 w-14 md:h-20 md:w-20 text-[0.7rem] md:text-[0.9rem] rounded-full">
                      {item.points}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PointSystem;
