import React from "react";
import PointSystem from "./PointSystem";
import TableThree from "./TableThree";
import TableTwo from "./TableTwo";
import { Link } from "react-router-dom";
import style from './HomeGuid.module.css'
import { IoMdExit } from "react-icons/io";

const Guideline = () => {
  return (
    <div className="p-4">
      {/* <Navbar /> */}
      <div className="pt-10 relative swing-in-top-fwd mb-28">
        <Link to="/">
          <div className="absolute top-8 right-7 cursor-pointer rounded-xl">
            <IoMdExit size={"2rem"} />
          </div>
        </Link>
        <h1 className={`w-full mt-6 h-[155px] py-8 text-white sm:text-sm text-[2rem] md:text-[5rem] font-nexabold font-semibold text-center tracking-normal leading-normal ${style.textShadow}`}>
          GUIDELINES
        </h1>
        <div className={`font-nexa rounded-lg p-5 mx-4 md:mx-auto text-justify text-base sm:text-lg items-center justify-center flex flex-col gap-y-5 font-normal mb-5 bg-gradient-to-br from-[#291263] to-[#21232F] to-96% ${style.gradientBorder} xl:right-0 lg:right-64 lg:w-[86rem]`}>
          <p className="p-4 md:p-8">
            The Campus Ambassadors will be assigned different ranks on the basis
            of their points. Rank system is only applicable to Campus
            Ambassadors with points more than 499, i.e. Campus Ambassadors
            eligible for participation certificate and other incentives. Campus
            Ambassadors can climb up the ranks by stacking points and obtaining
            +50 bonus points that comes along with rank upgrades.Campus
            Ambassadors will get sorted into 6 clans to effectively manage their
            workflow and to improve their interaction.
          </p>
        </div>
        <div className="justify-around items-center mt-16 text-2xl md:text-6xl font-bold hidden md:flex">
          <h1 className={`font-nexabold tracking-normal ${style.textShadow}`}>RANKERS</h1>
          <h1 className={`font-nexabold tracking-normal mr-10 ${style.textShadow}`}>POINTS</h1>
        </div>
        <div className="flex flex-wrap flex-col justify-center font-bold md:mt-10 gap-4 sm:mx-6">
          <div className="flex justify-around items-center text-4xl font-bod md:hidden">
            <h1 className={`flex font-nexabold text-2xl tracking-normal md:hidden  ${style.textShadow}`}>Rank</h1>
            <h1 className={`flex font-nexabold text-2xl tracking-normal  md:hidden ${style.textShadow}`}>Points</h1>
          </div>
          {[
            { rank: "Cavalry", points: "499-1198" },
            { rank: "Apprentice", points: "1199-1998" },
            { rank: "Knight", points: "1999-2998" },
            { rank: "Lord", points: "2999-3998" },
            { rank: "King", points: "3999+" }
          ].map((item, index) => (
            <div key={index} className="flex justify-around items-center text-sm md:text-xl font-bold">
              <h1 className="flex justify-center items-center rounded-xl bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-10 w-32 md:h-16 md:w-60">{item.rank}</h1>
              <h1 className="flex justify-center items-center rounded-xl bg-gradient-to-br from-[#291263] to-[#21232F] to-96% h-10 w-32 md:h-16 md:w-60">{item.points}</h1>
            </div>
          ))}
        </div>
      </div>
      <PointSystem />
      <TableTwo />
      <TableThree />
    </div>
  );
};

export default Guideline;
